import { ConnectionSettings } from 'shared/api/ConnectionSettings';
import auth from 'shared/api/auth';
import { getHost } from 'shared/lib/url';
import userStore from 'entities/user/userStore';
import { ConnectionState } from 'shared/api/ConnectionState';

export type StateChangedHandler = (state: ConnectionState) => void;
class Connection {
  private _stateCallbacks: StateChangedHandler[] = [];
  public settings: ConnectionSettings | undefined;

  constructor() {
    void this.startupFlow();
  }

  public async loadConnectionSettings(): Promise<ConnectionSettings> {
    const host = getHost();
    const response = await fetch(`${host}/supply-auth/config`);
    const connectionSettings = await (response.json() as Promise<ConnectionSettings>);
    return connectionSettings;
  }

  public onStateChanged(callback: StateChangedHandler): void {
    this._stateCallbacks.push(callback);
  }

  public offStateChanged(callback: StateChangedHandler): void {
    this._stateCallbacks = this._stateCallbacks.filter((x) => x !== callback);
  }

  private async initAuth(): Promise<boolean> {
    if (!this.settings) {
      return false;
    }

    await auth.init(this.settings);
    return true;
  }

  public async startupFlow(): Promise<void> {
    try {
      // Настройки подключения.
      this.settings = await this.loadConnectionSettings();
      // Авторизация.
      await this.initAuth();

      // Загрузка информации о пользователе.
      await userStore.loadUser();
    } catch {
      /* empty */
    }
  }
}

export default new Connection();
