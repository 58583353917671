import { makeAutoObservable, runInAction } from 'mobx';
import api from 'shared/api/api';
import auth from 'shared/api/auth';
import { StateType, UserRole, UserType } from './model';

class UserStore {
  /**
   * Статус загрузки текущего юзера с сервера.
   */
  public state: StateType = 'Pending';

  /**
   * Данные о текущем пользователе.
   */
  public user: UserType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Загрузка данных о текущем пользователе.
   */
  public async loadUser() {
    this.state = 'Pending';
    this.user = null;
    const token = await auth.getToken();

    try {
      const role = await api.get<UserRole>(`v2/identity/client_role`, false, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      runInAction(() => {
        this.user = { role };
        this.state = 'Done';
      });
    } catch {
      runInAction(() => {
        this.state = 'Error';
      });
    }
  }
}

export default new UserStore();
