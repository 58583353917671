const colors = {
  brand: {
    primary: '#2B2AEC',
    secondary: '#FF5F19',
    white: '#FFFFFF',
    white50: 'rgba(255, 255, 255, 0.50)',
  },
  text: {
    primary: '#000000',
    secondary: '#858585',
    disabled: '#C2C2C2',
    gray: '#666668',
    link: '#1400DC',
    linkVisited: '#6A4FF6',
    lightGrey: '#7f8285',
  },
  icon: {
    primary: '#000000',
    secondary: '#AFAFAF',
    active: '#1400DC',
    disabled: '#DADBDD',
    bgOtherCompanyIcon: '#F4F7FA',
    fileBlue: '#75ADEA',
    fileGreen: '#69C57F',
    fileRed: '#FB6A6A',
    fileOrange: '#ED9254',
    gray: '#8E8E93',
  },
  accent: {
    success: '#00AC5F',
    successBackground: '#E9FAEF',
    error: '#EB2A38',
    errorBackground: '#FFEBEF',
    warning: '#FC9403',
    warningBackground: '#FFF3DC',
    active: '#3081FA',
    activeBackground: '#EBF5FF',
    cancel: '#AFAFAF',
    cancelBackground: '#F3F3F5',
    selected: '#0042690d',
  },
  buttons: {
    active: '#1400DC',
    hover: '#2B2AEC',
  },
  switch: {
    checked: '#2F80ED',
  },
  background: {
    primary: '#FCFCFC',
    secondary: '#F5F5F5',
    secondaryHover: '#EBEBEB',
    secondaryActive: '#DDDDDD',
    cargoDetails: '#f4f6fb',
    success: '#24C38E',
    ghost: '#00203314',
  },
  divider: {
    primary: '#EFEFEF',
  },
  border: {
    disabled: '#F9F9F9',
    active: '#EBEBEB',
    hover: '#DADBDD',
    focus: '#B9B9BB',
    primary: '#EFEFEF',
    gray: ' #e5e5e5',
  },
  opacity: {
    background: 0.1,
  },
  local: {
    white10: '#ffffff1a',
    icon: '#727CC0',
    active: '#3081FA',
    active3: '#5066FF08',
    active5: '#0057ff12',
    active40: '#5066ff66',
    bgLanding: '#F3FAFF',
    black: '#212121',
    black10: '#2121211A',
    black20: '#00000033',
    black50: '#808080',
    black60: '#00000099',
    black90: '#212121e5',
    grayDark: '#3F4A56',
    gray30: '#d1d6db',
    gray: '#667587',
    green: '#009688',
  },
  tr: {
    success: '#24c38e',
    hover: '#e3eaf5',
  },
  message: {
    inProcessing: '#3080FA33',
    executed: '#FFF3DC',
    clarify: '#727CC033',
    calc: '#00ac5f33',
    approve: '#727CC033',
    cancelPerformer: '#FFEBEF',
    cancelCustomer: '#FFEBEF',
    sendMessage: '#9797971A',
    text: '#383838',
    date: '#787878',
  },
  typo: { alert: '#EB3333', ghost: '#0020334d', controlClear: '#00395CCC', primary: '#002033' },
};

export default colors;
