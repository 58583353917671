import { getLocationOrigin } from '.';
/**
 * Returns server host.
 */
export function getHost(): string {
  if (process.env.NODE_ENV === 'development') {
    if (!process.env.REACT_APP_HOST) {
      throw new Error('Undefined REACT_APP_HOST variable.');
    }

    return process.env.REACT_APP_HOST;
  }

  return getLocationOrigin();
}
