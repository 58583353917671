const DEFAULT_PADDING = 8;

const multipliers = {
  /**
   * Multiplier: 0.25
   */
  quater: 0.25,

  /**
   * Multiplier: 0.5
   */
  half: 0.5,

  /**
   * Multiplier: 1.5
   */
  medium: 1.5,

  /**
   * Multiplier: 2
   */
  double: 2,

  /**
   * Multiplier: 2.5
   */
  huge: 2.5,

  /**
   * Multiplier: 3
   */
  triple: 3,
};

const paddings = {
  multipliers,
  values: {
    main: DEFAULT_PADDING,
    quater: DEFAULT_PADDING * multipliers.quater,
    half: DEFAULT_PADDING * multipliers.half,
    medium: DEFAULT_PADDING * multipliers.medium,
    double: DEFAULT_PADDING * multipliers.double,
    huge: DEFAULT_PADDING * multipliers.huge,
    triple: DEFAULT_PADDING * multipliers.triple,
  },
};

export default paddings;
