import StartupScreen from './ui/startupScreen/StartupScreen';
import { useLocalization } from 'entities/localization';
import { observer } from 'mobx-react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useStartup } from 'shared/hooks/useStartup';

const Layout = React.lazy(() => import('./ui/default'));

const App: React.FC = () => {
  const { L } = useLocalization();
  useStartup();

  return (
    <>
      <React.Suspense fallback={<StartupScreen text={L.Features.Startup.AppLoadingText} />}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Layout />
        </BrowserRouter>
      </React.Suspense>
    </>
  );
};

export default observer(App);
