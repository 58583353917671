import axios, { AxiosError, AxiosInstance, AxiosResponse, ResponseType } from 'axios';
import { getHost } from '../lib/url';
import auth from './auth';

function parseResponseData<T>(data: string): T {
  return JSON.parse(data) as T;
}

type ApiSettings = {
  host: string;
  apiPrefix: string;
};

type RequestConfig = {
  headers?: Record<string, string>;
  responseType?: ResponseType;
};

class API {
  private readonly settings: ApiSettings = {
    host: getHost(),
    apiPrefix: '/api',
  };

  private async createRequest(
    requiredAuth?: boolean,
    config?: RequestConfig,
    overrideUrl?: string
  ): Promise<AxiosInstance> {
    let token = await auth.getToken();

    if (!(token && !requiredAuth)) {
      token = undefined;
    }

    return axios.create({
      baseURL: overrideUrl ?? this.settings.host + (this.settings.apiPrefix || ''),
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...config?.headers,
      },
      responseType: config?.responseType,
    });
  }

  private async processRequest<T>(
    action: (api: AxiosInstance) => Promise<AxiosResponse<T>>,
    requiredAuth = true,
    config?: RequestConfig,
    overrideUrl?: string
  ): Promise<T> {
    const request = await this.createRequest(requiredAuth, config, overrideUrl);

    try {
      const response = await action(request);
      const raw = !config?.responseType || config.responseType === 'json' || config.responseType === 'blob';
      return raw ? response.data : parseResponseData<T>(response.data as string);
    } catch (ex: unknown) {
      if (ex instanceof AxiosError) {
        const status = ex.response?.status;
        if ((status === 401 || status === 403) && this.onUnauthorized) {
          try {
            this.onUnauthorized();
          } catch {
            // empty
          }
        }
      }
      throw ex;
    }
  }

  public onUnauthorized: (() => unknown) | undefined;

  public async get<T>(url: string, requiredAuth?: boolean, config?: RequestConfig, overrideUrl?: string): Promise<T> {
    return await this.processRequest<T>((api) => api.get(url), requiredAuth, config, overrideUrl);
  }

  public async post<T>(
    url: string,
    data?: unknown,
    requiredAuth?: boolean,
    config?: RequestConfig,
    overrideUrl?: string
  ): Promise<T> {
    return await this.processRequest((api) => api.post(url, data), requiredAuth, config, overrideUrl);
  }

  public async put<T>(
    url: string,
    data?: unknown,
    requiredAuth?: boolean,
    config?: RequestConfig,
    overrideUrl?: string
  ): Promise<T> {
    return await this.processRequest((api) => api.put(url, data), requiredAuth, config, overrideUrl);
  }

  public async del<T>(url: string, requiredAuth?: boolean, config?: RequestConfig, overrideUrl?: string): Promise<T> {
    return await this.processRequest((api) => api.delete(url), requiredAuth, config, overrideUrl);
  }
}

export default new API();
