import { makeAutoObservable } from 'mobx';
import ru from './locales/ru';

export const strings = {
  ru,
};

class LocalizationStore {
  public strings: typeof ru;

  constructor() {
    this.strings = ru;
    makeAutoObservable(this);
  }

  public setLanguage(language: keyof typeof strings) {
    this.strings = strings[language];
  }
}

export default new LocalizationStore();
