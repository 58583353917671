type Fonts = {
  header1: React.CSSProperties;
  header2: React.CSSProperties;
  header3: React.CSSProperties;
  header4: React.CSSProperties;
  header5: React.CSSProperties;
  header6: React.CSSProperties;
  header7: React.CSSProperties;
  sectionHeader1: React.CSSProperties;
  sectionHeader2: React.CSSProperties;
  sectionHeader3: React.CSSProperties;
  sectionHeader4: React.CSSProperties;
  sectionHeader5: React.CSSProperties;
  subheader1: React.CSSProperties;
  subheader2: React.CSSProperties;
  subheader3: React.CSSProperties;
  subheader4: React.CSSProperties;
  subheader5: React.CSSProperties;
  body1: React.CSSProperties;
  body2: React.CSSProperties;
  body3: React.CSSProperties;
  body4: React.CSSProperties;
  button1: React.CSSProperties;
  button2: React.CSSProperties;
  button3: React.CSSProperties;
  caption1: React.CSSProperties;
  caption2: React.CSSProperties;
  caption3: React.CSSProperties;
  caption4: React.CSSProperties;
  caption5: React.CSSProperties;
  caption6: React.CSSProperties;
  caption7: React.CSSProperties;
  caption8: React.CSSProperties;
  degree1: React.CSSProperties;
};

const defaultFont: React.CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
};

const fonts: Fonts = {
  header1: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
  },
  header2: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '64px',
    lineHeight: 'normal',
  },
  header3: {
    ...defaultFont,
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
  },
  header4: {
    ...defaultFont,
    fontSize: '20px',
    lineHeight: '24px',
  },
  header5: {
    ...defaultFont,
    fontSize: '32px',
    lineHeight: '40px',
  },
  header6: {
    ...defaultFont,
    fontWeight: 700,
    fontSize: '16px',
  },
  header7: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '40px',
    lineHeight: '40px',
  },
  sectionHeader1: {
    ...defaultFont,
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '47px',
  },
  sectionHeader2: {
    ...defaultFont,
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '42px',
  },
  sectionHeader3: {
    ...defaultFont,
    fontSize: '40px',
    lineHeight: '40px',
  },
  sectionHeader4: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
  },
  sectionHeader5: {
    ...defaultFont,
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: 'normal',
  },
  subheader1: {
    ...defaultFont,
    fontSize: '16px',
    lineHeight: '24px',
  },
  subheader2: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  subheader3: {
    ...defaultFont,
    fontSize: '24px',
    lineHeight: '32px',
  },
  subheader4: {
    ...defaultFont,
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '27.5px',
  },
  subheader5: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '26px',
  },
  body1: {
    ...defaultFont,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2: {
    ...defaultFont,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body3: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
  },
  body4: {
    ...defaultFont,
    fontSize: '16px',
    lineHeight: '20px',
  },
  button1: {
    ...defaultFont,
    fontWeight: 500,
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '20px',
  },
  button2: {
    ...defaultFont,
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '16px',
  },
  button3: {
    ...defaultFont,
    fontWeight: 500,
    textTransform: 'none',
    fontSize: '20px',
    lineHeight: '20px',
  },
  caption1: {
    ...defaultFont,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.005em',
  },
  caption2: {
    ...defaultFont,
    fontSize: '12px',
    lineHeight: '16px',
  },
  caption3: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  caption4: {
    ...defaultFont,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.005em',
  },
  caption5: {
    ...defaultFont,
    fontSize: '18px',
    lineHeight: '20px',
  },
  caption6: {
    ...defaultFont,
    fontSize: '14px',
    lineHeight: '16px',
  },
  caption7: {
    ...defaultFont,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '1.4px',
  },
  caption8: {
    ...defaultFont,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: 'normal',
  },
  degree1: {
    ...defaultFont,
    fontSize: '9px',
    lineHeight: '10px',
  },
};

export default fonts;
