import connection from 'app/connection';
import React from 'react';
import { ConnectionState } from 'shared/api/ConnectionState';

export function useStartup() {
  const [state, setState] = React.useState<ConnectionState>('Connecting');

  const updateState = React.useCallback((value: ConnectionState) => {
    setState(value);
  }, []);

  React.useLayoutEffect(() => {
    connection.onStateChanged(updateState);
    return () => {
      connection.offStateChanged(updateState);
    };
  });

  return {
    state,
  };
}
