import i18n, { strings } from '../i18n';

/**
 * Локализация компонентов.
 * Все компоненты, использующие этот хук, должны быть обернуты в observer из mobx.
 */
export function useLocalization() {
  const setLanguage = (language: keyof typeof strings) => i18n.setLanguage(language);

  return {
    L: i18n.strings,
    setLanguage,
  };
}
