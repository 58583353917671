/**
 * Returns valid window.location.origin in IE11-.
 * @export
 * @returns {string}
 */
export function getLocationOrigin(): string {
  const location = window.location;
  let origin = location.origin;
  if (!origin) {
    origin = `${location.protocol}/${location.hostname}`;
    if (location.port) {
      origin += location.port;
    }
  }
  return origin;
}
