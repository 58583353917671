import { colors, fonts } from 'shared/theme/assets';
import './styles.scss';

export type StartupScreenProps = {
  text?: string;
};

/**
 * Компонент стартового экрана.
 * Должен быть максимально лёгким и не импортировать в себя тяжелые библиотеки, например, mui.
 */
const StartupScreen: React.FC<StartupScreenProps> = ({ text }) => {
  return (
    <div className="startup-screen">
      {/* Описание */}
      {text && (
        <div
          className="text"
          style={{
            ...fonts.caption2,
            color: colors.text.secondary,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default StartupScreen;
